let curRoute; //- идентификатор части формы, которая должна быть активна (city-city, city-airport...)


//Preloader
$(window).on('load', function() {
  $("#over").fadeOut().remove();
  $("#preload").delay(550).fadeOut("slow").remove();
});
//End Preloader

//Automatic update year
$(document).ready(function () {
  var d = new Date();
  var output = d.getFullYear();
  $('.yearval').text(output);
});
//End

// Открываем/Закрываем выбор языков по клику
$('.lang').click(function () {
  $('.lang__submenu').toggleClass('lang__submenu--visible');
})

// Закрываем выбор языков по клику снаружи
$(document).click(function (e){ // отслеживаем событие клика по веб-документу
    var langClick = $(".lang__submenu"); // определяем элемент, к которому будем применять условия (можем указывать ID, класс либо любой другой идентификатор элемента)
    // var block2=$('input[name="passengers"]');
    if((langClick.hasClass("lang__submenu--visible")) && (!langClick.is(e.target)) && (!($('.lang').is(e.target))) && (!($('.lang__visible').is(e.target)))) {
      langClick.removeClass("lang__submenu--visible");
    }
});


// Функция, отвечающая за правильный вывод направлений в from и to (приоритетные наверху, остальные в алфавитном порядке)
// locations - все направления для данного пункта отправления
// locationsTop - приоритетные направления (ключи)
function locationsTopBottom(fieldName, locations, locationsTop){
  let listOptionsTop={}; //приоритетные направления (ключ+название)
  let listOptionsBottom={}; //неприоритетные направления (ключ+назание)
  let listOptionsBottomSorted={}; //отсортированные по алфавиту неприоритетные направления (ключ+назание)
    locationsTop.forEach(function(item){ 
      listOptionsTop[item]=arrLocations[item]; //формируем список приоритетных направлений (ключ+название)
      let myIndex = locations.indexOf(item); //удаляем приоритетную точку из возможных направлений, оставляя только второстепенные точки (ключи)
      if (myIndex !== -1) {
        locations.splice(myIndex, 1);
      }
    });
    locations.forEach(function(item){ //формируем список неприоритетных направлений (ключ+название) (неотсортированный)
      listOptionsBottom[item]=arrLocations[item];
    });
    listOptionsBottomSorted=sortLocations(listOptionsBottom);

  for(let key in listOptionsTop){
    $('select[name='+fieldName+']').append(new Option(listOptionsTop[key], key));
  };
  $('select[name='+fieldName+']').append(new Option("-----", 'divider'));
  $("select[name="+fieldName+"] option[value='divider']").attr('disabled', true); //деактивируем разделитель
  for(let key in listOptionsBottomSorted){
    $('select[name='+fieldName+']').append(new Option(listOptionsBottomSorted[key], key));
  };
}


$(document).ready(function () {
  let lang=document.getElementsByTagName("html")[0].getAttribute("lang"); //определяем язык страницы
  initLocations(lang); // получаем полный список всех направлений на нужном языке (массив arrLocations в файле locations.js: ключ+значение)
  let allDirections=[]; //массив ключей всех направлений
  let arrLocationsTop=[]; //массив приоритетных направлений

    allDirections=Object.keys(arrLocations); // получаем ключи всех направлений 

    for(let i=0; i<arrPointsNames.length; i++){ //получаем ключи всех основных точек
      arrLocationsTop[i]=arrPointsNames[i]; //для from в качестве приоритетных направлений берем основные точки
    }
    locationsTopBottom("from", allDirections, arrLocationsTop); //правильный вывод направлений в from 
});


// Инвертирование направлений
$('.btn-inverse').click(function () {
  if(($('select[name="from"]').val()) && ($('select[name="to"]').val())) {
    var location1=$('select[name="from"]').val();
    var location2=$('select[name="to"]').val();
    $('select[name="from"]').val(location2);
    $('select[name="from"]').change();
    $('select[name="to"]').val(location1);
  }
  calcPrice();
}); 


//функция сортировки

function sortLocations(locations){
  let arrLocToSort = []; //массив объектов, который используем для сортировки по алфавиту
  let idLoc; //название первого свойства у всех объектов в массиве, который будем сортировать
  let nameLoc; //название второго свойства у всех объектов в массиве, который будем сортировать
  let count=0;
  let locationsSorted = {}; //результат работы функции: отсортированные по алфавиту направления
  //переносим названия свойств и их значения из объекта arrLocations в в
  //массив объектов arrLocToSort, чтобы можно было воспользоваться сортировкой по алфавиту
  //arrLocations имеет вид {taormina: "Taormina", agrigento: "Agrigento", ...}
  //arrLocToSort будет иметь вид [{idLoc: "taormina", nameLoc: "Taormina"}, {idLoc: "agrigento"}, nameLoc: "Agrigento"}, ...]  

  for(let key in locations){
    arrLocToSort[count] = {
      idLoc: key,
      nameLoc: arrLocations[key]
    }
    count++;
  }
  arrLocToSort.sort((x, y) => x.nameLoc.localeCompare(y.nameLoc)); //сортируем названия направлений по алфавиту
  arrLocToSort.forEach(function(item){ //копируем все из отсортированого arrLocToSort в объект locationsSorted, чтобы удобно было обращаться по ключу к названию направлений
    locationsSorted[item.idLoc]=item.nameLoc;
  });
  arrLocToSort={}; //очищаем память, этот массив объектов больше не нужен
  return(locationsSorted);
}


///- Заполняем ценами offerte на главной странице
$(document).ready(function(){
  $(".offers__card__price").each(function(){
    let tmp=$(this).attr('key');
    let numPax=$(this).attr('pax');
    let offerPrice=arrCataniaAirport[tmp][numPax-1];
    $(this).text("€ "+offerPrice);
  });
});



/*
  Изменение FROM

*/

$('select[name="from"]').change(function () {
  let from=$(this).val();
  let allDirections=[]; //массив ключей возможных направлений/главных точек из FROM
  let arrLocationsTop=[]; //массив приоритетных направлений, совпадает со свойством-массивом TOP из объекта текущего города arrTaormina/arrCataniaAirport/...
  let allDirectionsSorted=[];
  let varOptionDefault=$('select[name="from"] option:first').text(); //define option in current language to use for select TO

  $('select[name="to"]').empty();//очищаем список TO при выборе точки в списке FROM
  $('select[name="to"]').append(new Option(varOptionDefault, '')); //добавляем в TO первую опцию, а именно "Select location", которая совпадает с первым элементом списка FROM
  $('select[name="to"] option:first').prop('disabled',true);
  $('select[name="to"] option:first').prop('hidden',true); 
  $("#price").val(''); //clear PRICE

  if(arrPointsNames.includes(from)){ //if FROM is one of main points
    allDirections=Object.keys(arrPoints[from]); // define the keys of locations to output in TO
    allDirections.splice(0, 1); //удаляем первый элемент массива, т.к. он не является городом, это элемент "TOP"
    arrLocationsTop=arrPoints[from]['top'];   
    locationsTopBottom("to", allDirections, arrLocationsTop); //правильный вывод направлений в to

  }else{ //if FROM isn't one of main points
    let arrCurrentPoint = []; //array of towns to go from this current main point
    arrPointsNames.forEach(function(item, index) { //item - the value of the current element of array, index - its key (number)
      arrCurrentPoint=Object.keys(arrPoints[item]); //define all towns from the current main point
      if(arrCurrentPoint.includes(from)){ //if we find FROM among the towns of the current main point
        allDirections.push(item); // add the current main point to output in TO
      }
    });
    // allDirectionsSorted=sortLocations(allDirections);
    allDirections.forEach(function(item){ //формируем список направлений с ключами и значениями (неотсортированный)
      $('select[name="to"]').append(new Option(arrLocations[item], item));
    });
  }

});


$('input[name="phone"]').click(function(){
  if(!$(this).hasClass('was-alerted')){
      $("#phoneModal .modal").removeClass('hidden');
      $("#phoneModal .modal__content").removeClass('hidden');  
      $(this).addClass('was-alerted');  
  }
});




//Открытие подменю "Выбор пассажиров"
// Как только поле "Пассажиры" получает фокус, открываем подменю и убираем фокус с поля "Пассажиры", чтобы функция правильно срабатывала при мгновенном повторном нажатии на "Пассажиры""
$('input[name="passengers"]').focusin(function(){
  $(".passengers__submenu").toggleClass("hidden");
  $(this).blur();
});

 
//Пересчитываем стоимость при изменении места назначения
$('select[name="to"]').change(function () {
  if($('select[name="from"]').val()!=null){
    calcPrice();    
  }
});

//Пересчитываем стоимость, если меняем флажок "Add round-trip" 
$('input[name="roundtrip"]').click(function () {
  calcPrice();
});


function isset(obj) {
  if (typeof obj === "string" && obj.trim() === "") {
   return false;
  }else if (typeof obj === 'undefined' || obj === null) {
    return false;
  } else {
    return true;
  }
}

//Калькулятор стоимости
function calcPrice(){
  let pax= parseInt($(".passengers__total").val());
  let from=$("#from option:selected").val();
  let dest=$("#to option:selected").val();
  let isRoundTripChecked=$("input[name='roundtrip']").prop("checked");
  let mainPoint;
  let location;
  let routeSelected;
  let priceToShow;
  if((isset(pax))&&(isset(from))&&(isset(dest))){
    if(arrPointsNames.includes(from)){
      mainPoint=from;
      location=dest;
    }else if(arrPointsNames.includes(dest)){
      mainPoint=dest;
      location=from;
    }
    routeSelected = arrPoints[mainPoint][location]; //array of prices for selected route
    let paxType=routeSelected.length;
    if(paxType==2){
      if(pax<5){
        priceToShow=routeSelected[0];
      }else{
        priceToShow=routeSelected[1];
      }
    }else if(paxType==8){
      priceToShow=routeSelected[pax-1];
    }else{
      alert("We are sorry, but there is an error in our system for this roate. Please, contact us!")
      priceToShow="";
    }
    if(isRoundTripChecked){
      priceToShow=2*priceToShow;
    }
    $("input[name='price']").val("€ "+priceToShow);
  } 
}



$(document).click(function (e){ // отслеживаем событие клика по веб-документу
    var block1 = $(".passengers__submenu"); // определяем элемент, к которому будем применять условия (можем указывать ID, класс либо любой другой идентификатор элемента)
    var block2=$('input[name="passengers"]');
    if((!block1.hasClass("hidden")) && (!block1.is(e.target)) && (!block2.is(e.target)) && (block1.has(e.target).length === 0)) {
      block1.addClass("hidden");
    }
});


$(".increase").click(function(){
  changePassengers($(this), ".increase", 0);
});

$(".decrease--children").click(function () {
  changePassengers($(this), ".decrease", 0);
})

$(".decrease--adults").click(function () {
  changePassengers($(this), ".decrease", 1);
})

//Функция активирует/деактивирует кнопки добавления и удаления пассажира
function changePassengers(thisBtn, btnName, minVal){
  var maxSeats=8; // max число пассажирских мест в такси
  if(btnName==".increase"){
    var numGroupPassengers=parseInt(thisBtn.siblings('input').val()) + 1; //Новое число пассажиров в данной группе
    var totalPassengers=parseInt($(".passengers__total").val()) + 1; //Увеличиваем значение в счетчике пассажиров
    var btnToEnable=".decrease";
  }
  else if(btnName==".decrease"){
    var numGroupPassengers=parseInt(thisBtn.siblings('input').val()) - 1;
    var totalPassengers=parseInt($(".passengers__total").val()) - 1; 
    var btnToEnable=".increase";
    if(numGroupPassengers==minVal){
       thisBtn.prop("disabled", true);
    }  
  }

  $(".passengers__total").val(totalPassengers); //Устанавливаем новое значение в счетчике пассажиров
  thisBtn.siblings('input').val(numGroupPassengers); //Изменяем число пассажиров в данной группе
  if(thisBtn.siblings(btnToEnable).prop("disabled", true)){
    thisBtn.siblings(btnToEnable).prop("disabled", false);
  }
  var totalAdults=parseInt($('input[name="adults"]').val());
  var totalChildren=totalPassengers-totalAdults;
  var freeSeats=maxSeats-totalPassengers; //считаем количество свободных мест
  var strPassengers=$(".label-adults").text() + ": " + totalAdults + "; " + $(".label-children").text() + ": " +  + totalChildren;
  $('input[name="passengers"]').val(strPassengers);
  if(freeSeats<=0){
    $(".increase").prop("disabled", true); //если свободных мест нет, блокируем все кнопки добавить
  } else {
    $(".increase").prop("disabled", false); //иначе активируем кнопки добавить
  }
  calcPrice();  
}


//Функция активирует/деактивирует кнопки добавления и удаления пассажира
function btnIncreaseDecrease(){
  var maxSeats=8; // max число пассажирских мест в такси
  var totalPassengers=parseInt($(".passengers__total").val()) ; //Получаем значение в счетчике пассажиров;
  var freeSeats=maxSeats-totalPassengers; //считаем количество свободных мест
  // В цикле проверяем количество пассажиров в каждой группе. Если оно 0 (для взрослых не 0, а 1),
  //то блокируем кнопку уменьшения, активируем кнопку добавления.
  $('.passengers__sub__number').each(function(index, value){
    if($(value).attr('id')=="adults"){
      var minPersons=1;
    }else{
      var minPersons=0;
    }

    if($(value).val()==minPersons){
      $(value).siblings('.decrease').prop("disabled", true);
      $(value).siblings('.increase').prop("disabled", false);
    }else{
      $(value).siblings('.decrease').prop("disabled", false);
    }

  });

  if(freeSeats<=0){
    $(".increase").prop("disabled", true); //если свободных мест нет, блокируем все кнопки добавить
  } else {
    $(".increase").prop("disabled", false); //иначе активируем кнопки добавить
  }

}

$('.passengers__reset').click(function(){
  $('.passengers__sub__number').each(function(index, value){
    if($(value).attr('id')=="adults"){
      $(value).val('1');
    }else{
      $(value).val('0');
    }
  });
  var strPassengers=$(".label-adults").text() + ": 1; " + $(".label-children").text() + ": 0";
  $('input[name="passengers"]').val(strPassengers);
  $('.passengers__total').val(1);
  btnIncreaseDecrease();
  calcPrice();
});


$('.passengers__ok').click(function(){
  $('.passengers__submenu').addClass('hidden'); 
})


// Плавный переход к якорю
$(document).ready(function () {
  $(".soft-scroll").on("click", function (a) {
    a.preventDefault();
    var c = $(this).attr("href"),
      t = $(c).offset().top;
    $("body,html").animate({
      scrollTop: t
    }, 1e3)
  })
});






$(document).ready(function(){
  var url = window.location.pathname;
  var filename = url.substring(url.lastIndexOf('/')+1);
  // if ((window.location.pathname == "index.html")||(window.location.pathname == "/ru/")||(window.location.pathname == "/it/")||(window.location.pathname == "/index.html")) {
  if ((filename=="")||(filename=="index.html")){
    $('.allbeauty').slick({
      slidesToShow: 5,
      touchThreshold:10,
      prevArrow:'<button type="button" class="slick-prev"></button>',
      nextArrow:'<button type="button" class="slick-next"></button>',
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3
          }
        },
      ]
    });
  };
});



$(document).ready(function(){
  $('.spoiler-body').hide(300);
  $(".spoiler-wrap").click(function (e){
    e.preventDefault();
    if ($(this).hasClass('active')){
      $(this).removeClass('active');
      $(this).find('.spoiler-body').slideToggle();
      $(this).toggleClass("icon-plus-square");
      $(this).toggleClass("icon-minus-square");
    }
    else
    {
      $('.spoiler-wrap').removeClass('active');
      $('.spoiler-wrap').find('.spoiler-body').slideUp();
      $(this).addClass('active');
      $(this).find('.spoiler-body').slideToggle();
    }
  })
})




//- Menu close/open
$(document).ready(function () {
	$(".menu-button").click(function () {
		$(this).toggleClass("menu-button--to-open"),
		$(this).toggleClass("menu-button--to-close"),
		$(this).hasClass("menu-button--to-close") ? $(".menu").addClass("menu--visible") : $(".menu").removeClass("menu--visible")
	})
});

//- При изменении ширины окна браузера на ширине 1200px сворачивается меню, если оно было развернуто.
//- срабатывает событие клика на кнопку, чтобы и меню, и кнопка приняли необходимый вид для широких экранов
$(window).resize(function(){
	if($(window).width()>=1200){
		if($('.menu').hasClass('menu--visible')){
			$('.menu-button').click();
		}
	}
});



/*--------------PARALLAX -----------------*/

(function ($, window) {
  $.scrollParallax = function (el, options) {
    var _this = this;
    _this.$el = $(el);

    // Get option values ready
    options.tweenAmount = confineRange(options.tweenAmount, 1, 100);
    options.randomDepth.min = confineRange(options.randomDepth.min, 1, options.randomDepth.max);
    options.randomDepth.max = confineRange(options.randomDepth.max, options.randomDepth.min, 100);
    options.randomTween.min = confineRange(options.randomTween.min, 1, options.randomTween.max);
    options.randomTween.max = confineRange(options.randomTween.max, options.randomTween.min, 100);

    options.defaultDepth = confineRange(options.defaultDepth, 1, 100);

    if (options.random) {
      options.defaultDepth = Math.floor(Math.random() * (options.randomDepth.max - options.randomDepth.min) + options.randomDepth.min);
      options.tweenAmount = Math.floor(Math.random() * (options.randomTween.max - options.randomTween.min) + options.randomTween.min);
    }

    _this.loop = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.msRequestAnimationFrame || window.oRequestAnimationFrame || function (callback) { window.setTimeout(callback, 1000 / 60); };
    _this.looping = false;

    // Preloader for <img> elements
    if (_this.$el.is('img')) {
      var $tempImg = $('<img/>');

      $tempImg
        .on('load', () => {
          init();

          $tempImg = null;
        })
        .attr('src', $(el).attr('src'));
    } else {
      init();
    }

    function init() {
      // Set initial offset
      _this.currentOffset = 0;

      // Get initial transform matrix
      _this.matrix = parseMatrix(_this.$el.css('transform'));

      // Set parallax depth
      _this.depth = typeof _this.$el.attr(options.parallaxAttr) !== typeof undefined && _this.$el.attr(options.parallaxAttr) !== false ? parseInt(_this.$el.attr(options.parallaxAttr), 10) : options.defaultDepth;
      _this.depth = (100 - _this.depth) / 100;

      // Set reference element
      _this.$ref = _this.$el.closest('[' + options.referenceAttr + ']').length ? _this.$el.closest('[' + options.referenceAttr + ']') : _this.$el.parent();

      // Set tweenAmount
      _this.tweenAmount = options.tweenAmount / 100;

      // Remove attributes
      _this.$el.removeAttr(options.parallaxAttr);
      _this.$ref.removeAttr(options.referenceAttr);

      // Watch for scroll
      scrollWatch();
    }

    function startParallax() {
      _this.looping = true;
      draw();
    }

    function draw() {
      updatePositions();

      if (_this.looping) {
        _this.loop.call(window, function () {
          return draw();
        });
      } else {
        scrollWatch();
      }
    }

    function updatePositions() {
      var scrollTop = $(window).scrollTop();
      var matrix = _this.matrix.slice(0);
      var newOffset = (_this.$ref.offset().top - scrollTop - $(window).height() / options.neutralPoint) * (1 - _this.depth);

      if (options.tween) {
        var delta = newOffset - _this.currentOffset;
        newOffset = _this.currentOffset + (delta * _this.tweenAmount);

        if (Math.abs(delta) < options.threshold) {
          _this.looping = false;
        }
      }

      matrix[5] += newOffset;

      _this.$el.css('transform', buildMatrix(matrix));
      _this.currentOffset = newOffset;
    }

    function setEvents() {
      $(window).on('resize.parallax', function () {
        if (!_this.looping) {
          startParallax();
        }
      });
    }

    function scrollWatch() {
      $(window).one('scroll.parallax', function () {
        if (!_this.looping) {
          startParallax();
        }
      });
    }

    function buildMatrix(matrix) {
      return 'matrix(' + matrix.join(',') + ')';
    }

    function parseMatrix(str) {
      var matrix = [1, 0, 0, 1, 0, 0];
      var matrixPattern = /^\w*\(([-+]?[0-9]*\.?[0-9]+(?:[eE][-+]?[0-9]+)?), ([-+]?[0-9]*\.?[0-9]+(?:[eE][-+]?[0-9]+)?), ([-+]?[0-9]*\.?[0-9]+(?:[eE][-+]?[0-9]+)?), ([-+]?[0-9]*\.?[0-9]+(?:[eE][-+]?[0-9]+)?), ([-+]?[0-9]*\.?[0-9]+(?:[eE][-+]?[0-9]+)?), ([-+]?[0-9]*\.?[0-9]+(?:[eE][-+]?[0-9]+)?)\)/i;

      if (matrixPattern.test(str)) {
        matrix = str.match(matrixPattern);
        matrix.shift();

        for (var i = 0; i < matrix.length; i++) {
          matrix[i] = Number(matrix[i]);
        }
      }

      return matrix;
    }

    function confineRange(value, min, max) {
      return value > max ? max : value < min ? min : value;
    }
  };

  $.fn.scrollParallax = function (options) {
    var options = $.extend({
      defaultDepth: 20,
      neutralPoint: 50,
      random: false,
      randomDepth: {
        min: 50,
        max: 80
      },
      randomTween: {
        min: 8,
        max: 12
      },
      referenceAttr: 'data-ref',
      parallaxAttr: 'data-parallax',
      threshold: 0.1,
      tween: true,
      tweenAmount: 10
    }, options);

    return this.each(function () {
      var scrollParallax = new $.scrollParallax(this, options);
    });
  };

} (jQuery, window));



$('.animated-style1').scrollParallax({
      defaultDepth: 20,
      neutralPoint: 10,
      random: false,
});

$('.animated-style2').scrollParallax({
      defaultDepth: 20,
      neutralPoint: 20,
      random: false,
});

$('.animated-style3').scrollParallax({
      defaultDepth: 5,
      neutralPoint: 10,
      random: false,
});

/*--------------END PARALLAX -----------------*/





/*--------------Модальные окна-----------------*/


//Закрытие модального окна при клике на кнопки (крестик, ОК)
$(".modal-close").click(function () {
  $(".modal").addClass('hidden');
  $(".modal__content").addClass('hidden');
});

$(".modal-close-redirect").click(function () {
  $(".modal").addClass('hidden');
  $(".modal__content").addClass('hidden');
  var modalLang=$(this).parents(".modal-wrap");
  if(modalLang.hasClass('modalEn')) {
    window.location.href="/";    
  }
  else if(modalLang.hasClass('modalRu')) {
    window.location.href="/ru";    
  }
  else if(modalLang.hasClass('modalIt')) {
    window.location.href="/it";    
  }
});



/*------------------------------------------------ ---------------------------*/
/*--------------ФОРМА ОБРАТНОЙ СВЯЗИ ---------------------------------------*/
/*-------------------------------------------- -------------------------------*/


$("#form-contacts").submit(function (event) {
  var idForm="#form-contacts";
  event.preventDefault(); //- Останавливаем действие по умолчанию (отправка формы)
    if ($(this)[0].checkValidity() === false) {
      event.stopPropagation();
      $(idForm).addClass('was-validated');
      $("#formContactsResult .modal").removeClass('hidden');
      $("#formContactsResult .modal-error").removeClass('hidden');
      return(false);
    } else {
      doajax(idForm);
      $(this).removeClass("was-validated");
    }
});



/*------------------------------------------------ ---------------------------*/
/*--------------Календарь (datepicker) ---------------------------------------*/
/*-------------------------------------------- -------------------------------*/


$(function() {
    $.datepicker.setDefaults($.datepicker.regional['it']);
    $("input[myid='date']").datepicker({
     minDate: "+1",
     dateFormat: "d-M-yy"
    });
    $("#date").datepicker({
     minDate: "+1",
     dateFormat: "d-M-yy"
    });
    $('.ui-datepicker').addClass('notranslate');
}); 



/*
  Если у поля есть атрибут readonly, то не работает required.
  Хак: добавляем к полю атрибут readonly при получении фокуса, удаляем - при потере
  В этом случае пользователь не может вводить свои значения в поле
*/

$(function() {
 $("input[myid='date']").focusin(function() {
    $(this).attr("readonly", true);
 });
}); 


$(function() {
 $("input[myid='date']").focusout(function() {
    $(this).removeAttr("readonly");
 });
}); 


$(function() {
 $('#date').focusin(function() {
    $('#date').attr("readonly", true);
 });
}); 

$(function() {
 $('#date').focusout(function() {
    $('#date').removeAttr("readonly");
 });
}); 



/*------------------------------------------------ ---------------------------*/
/*--------------ЗАКАЗ ТРАНСФЕРА (с transfer.html) ------------------------------*/
/*-------------------------------------------- -------------------------------*/


//- Определяем тип формы, которую нужно показать (city-city, airport-city и т.д.)
function typeBooking(from, to){
  let point1;
  let point2;
    if(airports.includes(from)){
      point1="airport";
    }else if(ports.includes(from)){
      point1="port";
    }else{
      point1="city";
    }
    if(airports.includes(to)){
      point2="airport";
    }else if(ports.includes(to)){
      point2="port";
    }else{
      point2="city";
    }
    return(routes[point1][point2]);
}


//- Проверка полей формы на transfer.html
$(".btn--step1").click(function (event) {
  var idForm="#mainform"; //форма на главной странице
    if ($(idForm)[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        $(idForm).addClass('was-validated');
        $("#errorsModal .modal").removeClass('hidden');
        $("#errorsModal .modal-step1").removeClass('hidden');

    } else {
      $(idForm).removeClass("was-validated");
      var varfrom=$('#from option:selected').val();
      var varto=$('#to option:selected').val();
      var varpassengers=$('#passengers').val();
      var varpersons=$('#persons').val();
      var varadults=$('#adults').val();
      var varchildrensmall=$('#childrensmall').val();
      var varchildrenmedium=$('#childrenmedium').val();
      var varchildrenbig=$('#childrenbig').val();
      var varprice=$('#price').val();
      var varroundtrip=$('#roundTripCheck').is(":checked");
      // var varactivepart=typeBooking(varfrom, varto);

      var objExc = {
        formfrom: varfrom,
        formto: varto,
        formpassengers: varpassengers,
        formpersons: varpersons,
        formadults: varadults,
        formchildrensmall: varchildrensmall,
        formchildrenmedium: varchildrenmedium,
        formchildrenbig: varchildrenbig,
        formprice: varprice,
        formroundtrip: varroundtrip,
        // formactivepart: varactivepart,
      };

    var serialObj = JSON.stringify(objExc); //сериализуем его
    localStorage.setItem("myKey", serialObj); //запишем его в хранилище по ключу "myKey"

      $(idForm)[0].reset();
        //do your ajax submition here
        // $(".btn--step1").trigger(event);
    }
});



// Если мы на transfer.html,
// то при перезагрузке страницы очищаем форму заказа такси

$(document).ready(function () {
  var url = window.location.pathname;
  var filename = url.substring(url.lastIndexOf('/')+1);
  if ((filename == "") || (filename == "transfer.html")){
      $('#mainform')[0].reset();  
      $('.passengers__reset').click(); //заново пересчитываем пассажиров и стоимость
  }
});


var filename; //- выносим эту переменную, чтобы она была доступна для разных скриптов


/*
    Перенос данных о маршруте на другую страницу
    (с transfer.html на booking-transfer.html)
*/

$(document).ready(function () {

  var url = window.location.pathname;
  filename = url.substring(url.lastIndexOf('/')+1);
  
  // Если мы на странице заказа такси booking.html,
  // то переносим данные о маршруте и пассажирах из myKey на эту страницу, если произошел переход с transfer.html
  // или же очищаем все формы
  if (filename == "booking-transfer.html") {
    var returnObj = JSON.parse(localStorage.getItem("myKey"));
    
  //- если returnObj пустой (происходит при перезагрузке или при прямом открытии booking.html), то очищаем все формы и показываем шаг1
    if(returnObj == null){
      // window.location.href = '/'; 
      $("#step1").removeClass('hidden');
      $('#step2').addClass('hidden');
      $('#step3').addClass('hidden');
      $('#booking')[0].reset();  
      $('#booking2')[0].reset();  
      $('#formsummary')[0].reset(); 
      $('.passengers__reset').click(); //заново пересчитываем пассажиров и стоимость
      return(false); //если с transfer.html не поступили данные, то выходим из функции, следующие строчки игнорируются
    }

    //- если returnObj не пустой, то получаем из него значения полей
    let from = returnObj['formfrom'];
    let to = returnObj['formto'];

    $('#from').val(from);
    $('select[name="from"]').change(); //- формируем список в TO на странице booking
    $('#to').val(to);
    $('#persons').val(returnObj['formpersons']);
    $('#passengers').val(returnObj['formpassengers']);
    $('#adults').val(returnObj['formadults']);
    $('#childrensmall').val(returnObj['formchildrensmall']);
    $('#childrenmedium').val(returnObj['formchildrenmedium']);
    $('#childrenbig').val(returnObj['formchildrenbig']);
    $('#roundTripCheck').prop('checked', returnObj['formroundtrip']);
    $('#price').val(returnObj['formprice']);


  $("#booking2").removeClass('was-validated'); //убирает подсветку ошибок, если мы уже вносили данные на шаге 2
  // // var strOneway=$('#from option:selected').text() + '  →  ' + $('#to option:selected').text();
  // // var strRoundtrip=$('#to option:selected').text() + '  →  ' + $('#from option:selected').text() 
  // var strOneway=$('#from').val() + '  →  ' + $('#to').val();
  // var strRoundtrip=$('#to').val() + '  →  ' + $('#from').val(); 
  // // $("#step1").addClass('hidden');
  let locationName1=arrLocations[$('#from').val()];
  let locationName2=arrLocations[$('#to').val()];
  let strOneway=locationName1 + '  →  ' + locationName2;
  let strRoundtrip=locationName2 + '  →  ' + locationName1;
  $("#headeroneway").text(strOneway);
  if ($('#roundTripCheck').is(':checked')){
    $("#headerroundtrip").text(strRoundtrip);
    $(".tripReturn").removeClass('hidden');
    $('.tripReturn input').prop('required', true);
    $('#booking2 .heading_rettangle').removeClass('hidden');
  }else{
    $(".tripReturn").addClass('hidden');
    $('.tripReturn input').prop('required', false);
    // if(!($('.tripReturn')).hasClass('hidden')){
    //   $('.tripReturn').addClass('hidden');
    // }
    $('#booking2 .heading_rettangle').addClass('hidden');
  }
  $("#headerpersons").text($('#passengers').val());
  $("#headerprice").text($('#price').val());

    localStorage.removeItem("myKey");
    btnIncreaseDecrease(); //Блокируем или активируем кнопки добавить/удалить пассажира
    $(".btn--booking-step1").click(); // форсируем переход с главной страницы к шагу 2 в системе бронирования
  }
});


/*----------------------------------------------*/
/*--------------BOOKING STEP 1 -----------------*/
/*----------------------------------------------*/

// Переход с шага 1 к шагу 2 на странице бронирования
// Функция вызываетcя (форсированно) и при переходе с transfer.html



//Устанавливаем структуру по умолчанию в шаге 2
function setDefaultStructure(curFlexPart){
  $(curFlexPart+" .info-structure-group").addClass('hidden'); //скрываем все структуры
  $(curFlexPart+" .radio-item-hotel").attr("checked", true); //активируем переключатель Hotel
  $(curFlexPart+" .info-hotel-group").removeClass('hidden'); //показываем вкладку с полями для Hotel
  $(curFlexPart+" .structure__wrap input").removeAttr("required"); //убираем все обязательные поля
  $(curFlexPart+" .tripThere .info-hotel-group input").attr("required", true); //делаем обязательными поля вкладки Hotel на пути туда
}

$(".btn--booking-step1").click(function(){
  let flexStep2 = ".step2__changepart"; // класс, заменяющий .step2_changepart
  let curFlexStep2; // класс, заменяющий активный .step2_changepart
  let from=$('#from').val();
  let to=$('#to').val();
  $('#booking2')[0].reset();  //очищаем форму деталей, когда переходим с шага1
  $('#formsummary')[0].reset();  //очищаем, когда переходим с шага1
  if ($("#booking")[0].checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      $('#booking').addClass('was-validated');
      $("#errorsModal .modal").removeClass('hidden');
      $("#errorsModal .modal-step1").removeClass('hidden');
      return(false);
  }
  //- Определяем какие части формы активировать (city-city, airport-city...)
  curRoute=typeBooking(from, to);
  
  $(flexStep2).addClass("hidden");  //-для начала скрываем все части в шаге 2
  $(flexStep2 + " input").removeAttr("required"); //-убираем у всех инпутов обязательность
  $(flexStep2 + " select").removeAttr("required"); //-убираем у всех селектов обязательность
  
  curFlexStep2=".step2__changepart[title='"+curRoute+"']";
  
  $(curFlexStep2).removeClass('hidden');//-показываем только нужную часть в шаге 2
  $(curFlexStep2 + " select").attr("required", true); //делаем обязательными все селекты в активной части формы
  $(curFlexStep2 + " input").attr("required", true); //делаем обязательными все инпуты в активной части формы
  setDefaultStructure(curFlexStep2); //настраиваем структуры

  $("#booking2").removeClass('was-validated'); //убирает подсветку ошибок, если мы уже вносили данные на шаге 2
  
  let locationName1=arrLocations[from];
  let locationName2=arrLocations[to];
  let strOneway=locationName1 + '  →  ' + locationName2;
  let strRoundtrip=locationName2 + '  →  ' + locationName1;

  $("#step1").addClass('hidden');
  $("#headeroneway").text(strOneway);
  
  if ($('#roundTripCheck').is(':checked')){
    $("#headerroundtrip").text(strRoundtrip);
    $(curFlexStep2+" .tripReturn").removeClass('hidden');
    $(curFlexStep2+" .tripReturn input").prop('required', true);
    $(".step3 .tripReturn").removeClass('hidden');
  }else{
    $(".form-group-roundtrip").addClass('hidden');
    $(curFlexStep2+" .tripReturn input").prop('required', false);
    $('.tripReturn').addClass('hidden');
  }

  $("#headerpersons").text($('#passengers').val());
  $("#headerprice").text($('#price').val());

  $('.step2').removeClass('hidden');
  $('.step3').addClass('hidden');
  window.scrollTo(0, 0); //показываем верх страницы
});



/*----------------------------------------------*/
/*--------------BOOKING STEP 2 -----------------*/
/*----------------------------------------------*/

// Показываем поля в зависимости от выбора структуры (работает независимо и для структуры1 и для структуры2)
$('.structure-group input').change(function () {
  var valStructure=$(this).val(); //=hotel1a, house1a, bb1a, hotel2, house2...
  var attrName=$(this).attr('name'); //=typeStructure3, typeStructure2, typeStructure1...
  $('input[name='+attrName+']').each(function(){
    var groupName=".info-"+$(this).val()+"-group";
    if($(this).val()===valStructure){
      $(groupName).removeClass("hidden");
      $(groupName+" input").prop("required", true);
    }else{ //- скрываем, делаем необязательными и очищаем поля, которые не выбраны
      $(groupName).addClass("hidden");
      $(groupName+" input").prop("required", false);
      $(groupName+" input").val("");
    }
  });
});


function fullInfoStructure(context){ //strObj=".booking2[title="+structureTitle+"']""
  let struct=$("input:checked", context).val(); //typeStructure1a, typeStructure2a, typeStructure3...
  let curRadio=$("input:checked", context).attr("myid"); //radiohotel, radiohouse, radiobb...
  let fullInfo;
  if(curRadio==="radiohotel"){
    fullInfo=$(".radio-item-hotel label", context).text()  + " " + $(".info-hotel-group input", context).val();
  }else if(curRadio==="radiohouse"){
    fullInfo=$(".radio-item-house label", context).text() + " " + $(".house-name", context).val() + " – " + $(".house-address", context).val() + ", " + $(".house-number", context).val() + " – tel." + $(".house-phone", context).val();
  
  }else if(curRadio==="radiobb"){
    fullInfo=$(".radio-item-bb label", context).text() + " " + $(".bb-name", context).val() + " – " + $(".bb-address", context).val() + ", " + $(".bb-number", context).val() + " – tel." + $(".bb-phone", context).val();
  }else{
    fullInfo=$(".radio-item-other label", context).text() + " " + $(".other-name", context).val() + " – " + $(".other-address", context).val() + ", " + $(".other-number", context).val() + " – tel." + $(".other-phone", context).val();
  }
  return(fullInfo);
}


// Подсказки для полей в форме
$(".help-icon").hover(function() {
  $(this).siblings('.help-content').toggleClass('hidden');
});

// Шаг 2, кнопка возврата к шагу 1
$('.btn--tostep1').click(function (event) {
  $('.step2').addClass('hidden');
  $('.step1').removeClass('hidden');
  window.scrollTo(0, 0);
});

// Шаг 2, кнопка возврата к шагу 1
$('.btn-back-step2').click(function (event) {
  $('.step1').removeClass('hidden');
  window.scrollTo(0, 0);
  $('.step2').addClass('hidden');
  // window.location.href = '/'; 
});

$("input[name='messengers']").click(function() { //при клике на мессенджер убираем ошибку (рамку вокруг)
  $(".form__messengers").removeClass('form__messengers--err');
});

// Шаг 2, кнопка перехода к шагу 3 (Итог)
$('.btn--step2').click(function (event) {
  let curElement;
  let curFlexStep3;
  let elem1;
  let elem2;
  let varFullStructure; //полное инфо о структуре 
  let varFullStructure1; //полное инфо о структуре 1
  let varFullStructure2; //полное инфо о структуре 2

  //проверяем есть ли выбранные мессенджеры
  let selectedMess=0;
  $("#booking2 input[name='messengers']").each(function (index, value){
    if($(value).is(":checked")){
      return(false)
    }else{
      selectedMess++;
    }
  });
  //

  if ($("#booking2")[0].checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      $("#booking2").addClass('was-validated'); //- добавляем этот класс, чтобы подсвечивались ошибки
      $("#errorsModal .modal").removeClass('hidden');
      $("#errorsModal .modal-step2").removeClass('hidden');
      return(false);
  } else if(selectedMess==3){ //если не выбран ни один мессенджер, показываем ошибку
      $("#errorsModal .modal").removeClass('hidden');
      $("#errorsModal .modal-messengers").removeClass('hidden');
      $(".form__messengers").addClass('form__messengers--err');
      return(false);
  } else {
    $("#formsummary")[0].reset(); //очищаем итоговую форму на случай, если были какие-то изменения
    $("#booking2").removeClass("was-validated");// ?????
    $(".form__messengers").removeClass('form__messengers--err');
    $("#step2").addClass('hidden');
    $(".step3__changepart").addClass("hidden");
    $("#step3").removeClass('hidden');
    window.scrollTo(0, 0);
  }

  // Для проверки в php скрипте добавляем выбор клиента (откуда-куда, туда-тудаОбратно)
  $('input[name="fromto"]').val(curRoute); //- передаем откуда-куда (airport-city, city-city...)
  $('input[name="idroute"]').val($("#roundTripCheck").is(":checked")); //-передаем выбор клиента: в одно сторону или туда-обратно


    elem1=$("#booking2 input[name='name']").val();
    elem2=$("#booking2 input[name='surname']").val();

  let varFullName=elem1+" "+elem2;
    elem1=$("#booking2 select[name='prefix'] option:selected").text();
    elem2=$("#booking2 input[name='phone']").val();
  let varPhone=elem1+" " +elem2;

  let varDirection1=$('#headeroneway').text();
  let varDirection2=$('#headerroundtrip').text();
  
  $('#formsummary input[name="name"]').val(varFullName);
  $('#formsummary input[name="email"]').val($('#booking2 input[name="email"]').val());
  $('#formsummary input[name="phone"]').val(varPhone);

  let allMess="";
  $("#booking2 input[name='messengers']").each(function (index, value){
    let curMess=$(value).val();
    if($(value).is(":checked")){
      $("."+curMess).removeClass('hidden');
      allMess=allMess+curMess+" ";
    }else{
      $("."+curMess).addClass('hidden');
    }
  });
  $("#formsummary input[name='messengers']").val(allMess);

  $('#formsummary input[name="direction1"]').val(varDirection1);
  
  curFlexStep3=".step3__changepart[title='"+curRoute+"']";
  if ($('#roundTripCheck').is(':checked')){
    $('#formsummary input[name="direction2"]').val(varDirection2);
    $(".step3 .tripReturn").removeClass('hidden');
    $(".step3 .tripReturn "+curFlexStep3+" input").prop('required', true);
  }else{
    $(".step3 .tripReturn "+curFlexStep3+" input").prop('required', false);
    $('.step3 .tripReturn').addClass('hidden');
  }

  //-Копируем в итоговую форму полную информацию о структуре, если требуется
  if(curRoute=="city-city"){
    let tmp1=$(".structure__wrap:first", ".step2__changepart[title='city-city']");
    let tmpName=tmp1.attr('title');
    varFullStructure1=fullInfoStructure(tmp1);
    $('#formsummary input[name="structure1a"]').val(varFullStructure1); //-копируем инфо о структуре1 (полное) в итоговую ф.
    let tmp2=$(".structure__wrap:last", ".step2__changepart[title='city-city']");
    varFullStructure2=fullInfoStructure(tmp2);
    $('#formsummary input[name="structure1b"]').val(varFullStructure2); //-копируем инфо о структуре2 (полное) в итоговую ф.
    $(".sumStructure1", ".step3__changepart[title='city-city']").val(varFullStructure2);    
    $(".sumStructure2", ".step3__changepart[title='city-city']").val(varFullStructure1);
  }else if(curRoute.indexOf('city')!=-1){
    let tmp=$(".structure__wrap", ".step2__changepart[title='"+curRoute+"']");
    let tmpName=tmp.attr('title');
    varFullStructure=fullInfoStructure(tmp);
    $("#formsummary input[name='"+tmpName+"']").val(varFullStructure); //-копируем инфо о структуре1 (полное) в итоговую ф.
    $(".sumStructure", ".step3__changepart[title='"+curRoute+"']").val(varFullStructure);    
  }  
  

  $(".step3__changepart[title='"+curRoute+"']").removeClass('hidden');


  //Копируем в итоговую форму Summary данные из формы booking2 c деталями  (из шаг 2)
  //- '.usualinput' - обычные инпуты, которые не нужно объединять с другой информацией

  $(".step3 .usualinput").each(function (index, value){
    var inputName=$(value).prop('name');
    $(value).val($(".step2 input[name='"+inputName+"']").val());
  });

  //Копируем в итоговую форму Summary данные из формы Booking о пассажирах и маршруте (из шаг1)
  $('.summary-fromstep1').each(function (index, value){
    var inputName=$(value).prop('name');
    var varFromStep1=$('#booking input[name='+inputName+']').val();
    if(varFromStep1==0){
      $(value).val(''); //если значение поля 0, то делаем reset поля в итоговой форме (понадобится для php)
      $(value).parent('.summary-group').addClass('hidden');
    } else {
      $(value).val(varFromStep1);
      $(value).parent().removeClass('hidden');     
    }
  });

  $('.from-select').each(function (index, value){ //скрытое инфо, которое проверяется при проверке формы и передается на сервер. ex: инфо о багаже
    var selectName=$(value).prop('name');
    $(value).val($('#booking2 select[name='+selectName+'] option:selected').text());
  });
   
  $("#formsummary input[name='message']").val($('#booking2 textarea').val());

  $('.summary-text-input').each(function (index, value){
      $(value).text($(this).siblings('input').val()); //инфо, которое выводится в итоговой форме, но не проверяется. Только для удобства чтения
  });
  

});



/*----------------------------------------------*/
/*--------------BOOKING STEP 3 (Summary)-----------------*/
/*----------------------------------------------*/


$('.btn-back-step3').click(function (event) {
  $('#step2').removeClass('hidden');
  $('#step3').addClass('hidden');
  $(".step3__changepart").addClass('hidden');
  $('.step3 input').val('');
  $('#formsummary')[0].reset();
  window.scrollTo(0, 0);
});


// Проверка и отправка итоговой формы

$("#formsummary").submit(function (event) {
  var idForm="#formsummary";
  event.preventDefault(); //- Останавливаем действие по умолчанию (отправка формы)
    if ($(this)[0].checkValidity() === false) {
      event.stopPropagation();
      $(idForm).addClass('was-validated');
      // $("#errorModal").removeClass('hidden');
      // $("#errorModal .modal__step2").removeClass('hidden');
      $("#errorsModal .modal").removeClass('hidden');
      $("#errorsModal .modal-step2").removeClass('hidden');
      return(false);
    } else {
      doajax(idForm);
      $(this).removeClass("was-validated");
    }
});


/*----------------------------------------------*/
/*--------------ЗАКАЗ ТРАНСФЕРА НА ВИНОДЕЛЬНЮ-----------------*/
/*----------------------------------------------*/


// Передача информации об экскурсии в форму заказа
$(document).ready(function () {
  $(".btn-bookwinery").on("click", function () {
    var vartitle=$(this).parent().parent().find('.offers__card__title span').text();
    vartitle=vartitle.slice(0, -1); //- удаляем двоеточие в конце
    // vartitle=vartitle+$(this).parent().parent().find('.offers__card__subtitle').text();
    var varpersons=$(this).parent().find('.offers__card__npersons span').text();
    var varprice=$(this).parent().find('.offers__card__price').text();
    var objExc = {
      formtitle: vartitle,
      formpersons: varpersons,
      formprice: varprice
    };
    var serialObj = JSON.stringify(objExc); //сериализуем его
    localStorage.setItem("myKey", serialObj); //запишем его в хранилище по ключу "myKey"
  });
});



/*----------------------------------------------*/
/*--------------ЗАКАЗ ЭКСКУРСИЙ-----------------*/
/*----------------------------------------------*/

// Передача информации об экскурсии в форму заказа
$(document).ready(function () {
  $(".sendexcinfo").on("click", function () {
    var vartitle=$('#datatosendtitle span').text();
    var varguide=$(this).parent().parent().find('.prices__header').text();
    var varpersons=$(this).parent().find('.prices__npersons span').text();
    var varprice=$(this).parent().find('.prices__price').text();
    var objExc = {
      formtitle: vartitle,
      formguide: varguide,
      formpersons: varpersons,
      formprice: varprice
    };
    var serialObj = JSON.stringify(objExc); //сериализуем его
    localStorage.setItem("myKey", serialObj); //запишем его в хранилище по ключу "myKey"
  });
});


$(document).ready(function () {
  var url = window.location.pathname;
  var filename = url.substring(url.lastIndexOf('/')+1);
  if (filename == "booking-excursion.html") {
    var returnObj = JSON.parse(localStorage.getItem("myKey"));
    if(returnObj == null){
      $(".bookingexc-content").addClass('hidden'),
      $("#invaliddata").removeClass('hidden');      
    }else{
      $("#datatitle").val(returnObj['formtitle']);
      $(".datatitle--visible").text($("#datatitle").val());
      $("#dataguide").val(returnObj['formguide']);
      $("#datapersons").val(returnObj['formpersons']);
      $("#dataprice").val(returnObj['formprice']);
      localStorage.removeItem("myKey");
    }
  }else if (filename == "booking-transfer-winery.html") {
     var returnObj = JSON.parse(localStorage.getItem("myKey"));
    if(returnObj == null){
      $(".bookingexc-content").addClass('hidden'),
      $("#invaliddata").removeClass('hidden');      
    }else{
      $("#datatitle").val(returnObj['formtitle']);
      $(".datatitle--visible").text($("#datatitle").val());
      $("#datapersons").val(returnObj['formpersons']);
      $("#dataprice").val(returnObj['formprice']);
      localStorage.removeItem("myKey");
    } 	
  }
});


$("#bookingexc").submit(function (event) {
  var idForm="#bookingexc";
  event.preventDefault();
    if ($(this)[0].checkValidity() === false) {
      event.stopPropagation();
      $(idForm).addClass('was-validated');
      $("#errorsModal .modal").removeClass('hidden');
      $("#errorsModal .modal-step2").removeClass('hidden');
      return(false);
    } else {
      doajax(idForm);
      $(this).removeClass("was-validated");
    }
});



/*--------------------------------------------------------*/
/*--------------ОТПРАВКА ДАННЫХ НА СЕРВЕР-----------------*/
/*----------------(общая для всех форм)-------------------*/
/*--------------------------------------------------------*/

function doajax(activeform) {
  var t, a = $(activeform).serialize();
  var o=$(activeform).attr('action');
  if (activeform=="#form-contacts")
    var idModal="#formContactsResult"
  else
    var idModal="#formBookingResult";
  $.ajax({
    type: "POST",
    url: o,
    data: a,
    success: function (msg) {
      msg=$.trim(msg); //- удаляем лишние пробелы, символы, иначе из php файла получим строку success, которая не соответствует строке success в этом файле
      if (msg=="success") //сообщение успешно отправлено
        $(idModal+" .modal").removeClass('hidden'),
        $(idModal+" .modal-success").removeClass('hidden'),
        $(activeform)[0].reset()
      else if (msg=="failed") //сообщение не отправлено из-за ошибки на сервере
        $(idModal+" .modal").removeClass('hidden'),
        $(idModal+" .modal-unknown-error").removeClass('hidden'),
        $(activeform)[0].reset()
      else if (msg=="robot") //сообщение не отправлено, нет подтверждения что не робот
        $("#formContactsCaptcha .modal").removeClass('hidden'),
        $("#formContactsCaptcha .modal-error").removeClass('hidden')
      else //есть незаполненные поля, сообщение не отправлено (непустая $errorMSG в php-скрипте)
        $(idModal+" .modal").removeClass('hidden'),
        $(idModal+" .modal-php-error .modal__message").empty(),
        $(idModal+" .modal-php-error .modal__message").append(msg),
        $(idModal+" .modal-php-error").removeClass('hidden')    
    },
    beforeSend: function () {
      $(activeform).find(":input").attr("disabled", !0),
      $(activeform + " .ajax-loader").css("display", "block")
    },
    complete: function () {
      $(activeform).find(":input").attr("disabled", !1),
      $(activeform + " .ajax-loader").css("display", "none")
    }
  });
}

$('.excprices').parallax({
  speed: .3,
  afterRender: doSomething
})